export class NotificationSetting {
	notificationSettingId: string;
	portalId: string;
	verificationFrequency: number;
	notificationValue: number;
	createdById: string;
	dateTimeCreated: Date;
	dateTimeLastModified: Date;
	lastModifiedById: string;

	notificationSettingStatusTypeId: string;

	notificationJobTypeId: string;
	notificationJobTypeName: string;
	notificationJobTypeNameAsBase64: string;

	notificationDatepartTypeId: string;
	notificationDatepartName: string;
	notificationDatepartNameAsBase64: string;

	createdByName: string;
	createdByNameAsBase64: string;

	lastModifiedByName: string;
	lastModifiedByNameAsBase64: string;

	formControlId?: number;
}
