import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { NotificationSetting } from '@app/shared/notification-setting.model';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormComponent } from '@app/components/base-form/base-form.component';
import { NgForm } from '@angular/forms';
import { ModalReturnType } from '@app/app.enum';
import { notificationDatepartTypes, notificationJobTypes } from '@app/app.constants';
import { NotificationDatepartType } from '@app/shared/notifications';
import { FormService } from '@app/providers/form/form.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-add-notification-setting-modal',
	templateUrl: './add-notification-setting-modal.component.html',
	styleUrls: ['./add-notification-setting-modal.component.scss']
})
export class AddNotificationSettingModalComponent extends BaseFormComponent implements OnInit {
	@Input() notificationSetting: NotificationSetting;

	@Input() notificationDatepartTypes: Array<NotificationDatepartType>;

	maxDays: number = 365;

	maxMonths: number = 12;

	maxUnknown: number = 999;

	minValue: number = 1;

	constructor(public translateService: TranslateService,
		public elementRef: ElementRef,
		private formService: FormService,
		public dynamicDialogRef: DynamicDialogRef,
		public dynamicDialogConfig: DynamicDialogConfig) {
		super();
		this.init(this.dynamicDialogConfig.data.notificationSetting, this.dynamicDialogConfig.data.notificationDatepartTypes);
		this.dynamicDialogConfig.header = this.getTitle();
	}

	ngOnInit() {
		if (!this.notificationSetting) {
			this.notificationSetting = new NotificationSetting();
		}

		if (!this.notificationDatepartTypes) {
			this.notificationDatepartTypes = [
				{
					datepartTypeId: notificationDatepartTypes.day, // 'baba0540-0282-4aca-a53a-fb064ce2d509',
					datepartTypeName: this.translateService.instant('notificationSetting.datepartTypes.' + notificationDatepartTypes.day)
				},
				{
					datepartTypeId: notificationDatepartTypes.month, // 'f8b89b3c-8c9e-47c8-9a2e-9c5417d1a838',
					datepartTypeName: this.translateService.instant('notificationSetting.datepartTypes.' + notificationDatepartTypes.month)
				}
			];
		}
	}

	onSave(form: NgForm) {
		if (this.formService.isInvalid(this.elementRef)) return;

		if (!this.canSubmit(form.form)) {
			return;
		}

		if (!this.isValueValid(form)) {
			return;
		}

		this.dynamicDialogRef.close(ModalReturnType.save);
	}

	onDelete(form: NgForm) {
		if (!this.canSubmit(form.form)) {
			return;
		}

		this.dynamicDialogRef.close(ModalReturnType.delete);
	}

	onCancel() {
		this.dynamicDialogRef.close(ModalReturnType.cancel);
	}

	public init(ns: NotificationSetting, datepartTypes: Array<NotificationDatepartType>) {
		this.notificationSetting = ns;
		this.notificationDatepartTypes = datepartTypes;
	}

	getTitle(): string {
		const jobTypeName = this.translateService.instant('notificationSetting.notificationTypes.' + this.notificationSetting.notificationJobTypeId).toLowerCase();
		const frequencyName = this.translateService.instant('complianceSchedule.updateFrequencyOptions_' + this.notificationSetting.verificationFrequency).toLowerCase();
		return (this.notificationSetting.notificationSettingId)
			? this.translateService.instant('notificationSetting.editNotificationSetting', {
				a: jobTypeName,
				b: frequencyName
			})
			: this.translateService.instant('notificationSetting.addNotificationSetting', {
				a: jobTypeName,
				b: frequencyName
			});
	}

	getInstructions(): string {
		let instructions: string;
		const frequencyName = this.translateService.instant('complianceSchedule.updateFrequencyOptions_' + this.notificationSetting.verificationFrequency).toLowerCase();
		if (this.notificationSetting.notificationJobTypeId === notificationJobTypes.overdue) {
			instructions = (this.notificationSetting.notificationSettingId)
				? this.translateService.instant('help.notificationSetting.editOverdueNotificationSetting', {
					a: frequencyName
				})
				: this.translateService.instant('help.notificationSetting.addOverdueNotificationSetting', {
					a: frequencyName
				});
		}
		else if (this.notificationSetting.notificationJobTypeId === notificationJobTypes.dueSoon) {
			instructions = (this.notificationSetting.notificationSettingId)
				? this.translateService.instant('help.notificationSetting.editDueSoonNotificationSetting', {
					a: frequencyName
				})
				: this.translateService.instant('help.notificationSetting.addDueSoonNotificationSetting', {
					a: frequencyName
				});
		}
		else {
			instructions = '';
		}
		return instructions;
	}

	getNotificationValueTooltip(): string {
		let tooltip: string;
		if (this.notificationSetting.notificationJobTypeId === notificationJobTypes.overdue) {
			tooltip = this.translateService.instant('help.notificationSetting.notificationOverdueValueTooltip');
		}
		else if (this.notificationSetting.notificationJobTypeId === notificationJobTypes.dueSoon) {
			tooltip = this.translateService.instant('help.notificationSetting.notificationDueSoonValueTooltip');
		}
		else {
			tooltip = '';
		}
		return tooltip;
	}

	getMaxValue(): number {
		if (this.notificationSetting.notificationDatepartTypeId === notificationDatepartTypes.day) {
			return this.maxDays;
		}
		else if (this.notificationSetting.notificationDatepartTypeId === notificationDatepartTypes.month) {
			return this.maxMonths;
		}
		else {
			return this.maxUnknown;
		}
	}

	isValueValid(form: NgForm): boolean {
		if (this.notificationSetting.notificationValue < this.minValue) {
			form.controls['notificationValue'].setErrors({ invalidValue: true });
			return false;
		}
		else if (this.notificationSetting.notificationValue > this.getMaxValue()) {
			form.controls['notificationValue'].setErrors({ invalidValue: true });
			return false;
		}
		else {
			form.controls['notificationValue'].setErrors(null);
			return true;
		}
	}
}
