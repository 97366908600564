<div class="modal-body">
    <p>{{getInstructions()}}</p>
    <form #f="ngForm" novalidate>
        <div class="mb-3">
            <label class="form-label" for="notificationValue">{{ 'notificationSetting.notificationValue' | translate }} <i class="fa fa-question-circle"
                                                                     tooltipPosition="right"
                                                                     pTooltip="{{ getNotificationValueTooltip() }}"></i></label>
            <input type="number" class="form-control" id="notificationValue" aria-label="notificationValue" required
                   name="notificationValue" [(ngModel)]="notificationSetting.notificationValue"
                   min="{{ minValue }}" max="{{ getMaxValue() }}" (change)="isValueValid(f)">
        </div>
        <div class="mb-3">
            <label class="form-label" for="notificationDatepartTypeId">{{ 'notificationSetting.datepartType' | translate }} <i class="fa fa-question-circle"
                                                                                   tooltipPosition="right"
                                                                                   pTooltip="{{ 'help.notificationSetting.datepartTypeTooltip' | translate }}"></i></label>

            <select id="notificationDatepartTypeId" name="notificationDatepartTypeId"
                    class="form-select" aria-label="notificationDatepartTypeId"
                    [(ngModel)]="notificationSetting.notificationDatepartTypeId"
                    (change)="isValueValid(f)" required>
                <option [ngValue]="null">{{ 'select' | translate }}</option>
                <option *ngFor="let t of notificationDatepartTypes;" [ngValue]="t.datepartTypeId">{{ t.datepartTypeName }}</option>
            </select>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button id="cancelButton" type="button" class="btn btn-secondary" (click)="onCancel()">{{ 'common.cancel' | translate }}</button>
    <button id="deleteButton" type="button" class="btn btn-danger" (click)="onDelete(f)" *ngIf="notificationSetting && notificationSetting.notificationSettingId"><i class="fa fa-trash"></i></button>
    <button id="saveButton" type="button" class="btn btn-primary" (click)="onSave(f)">{{ 'save' | translate }}</button>
</div>
